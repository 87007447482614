import Agent from './agent'

const agent = new Agent()

/**
 * Adds a property and its value to a query string if the value is provided.
 *
 * @param {string} queryString The initial query string to which the property will be added.
 * @param {string} property The name of the property to add to the query string.
 * @param {string} value The value of the property to add. If falsy, the property is not added.
 * @returns {string} The updated query string with the new property and value added, if applicable.
 */
const addPropertyToQueryString = (queryString, property, value) => {
  if (value) {
    queryString += `&${property}=${value}`
  }
  return queryString
}

/**
 * Completes the agent query by adding additional parameters to the query string and then executing the query.
 *
 * This function takes a base query string and appends additional parameters to it, such as company ID, GTIN,
 * production step, and LGTIN owning company ID, if they are provided. After constructing the complete query string,
 * it executes the query using the agent's get method.
 *
 * @param {string} queryString The base query string to which additional parameters will be added.
 * @param {string} company The company ID to add to the query string.
 * @param {string} gtin The Global Trade Item Number (GTIN) to add to the query string.
 * @param {string} productionStep The production step to add to the query string.
 * @param {string} lgtinOwningCompanyId The LGTIN owning company ID to add to the query string.
 * @returns {Promise} A promise that resolves with the response from the agent's get method.
 */
const completeAgentQuery = (queryString, company, gtin, productionStep, lgtinOwningCompanyId) => {
  queryString = addPropertyToQueryString(queryString, 'companyId', company)
  queryString = addPropertyToQueryString(queryString, 'gtin', gtin)
  queryString = addPropertyToQueryString(queryString, 'productionStep', productionStep)
  queryString = addPropertyToQueryString(queryString, 'lgtinOwningCompanyId', lgtinOwningCompanyId)

  return agent.get(queryString)
}
export const transactionService = {
  getDetails: (from, to, company, gtin, productionStep, lgtinOwningCompanyId) => {
    let queryString = `/api/request_log_aggregation?fromDate=${from}&toDate=${to}&endpoint=/api/access`
    return completeAgentQuery(queryString, company, gtin, productionStep, lgtinOwningCompanyId)
  },
  getDetailsHistory: (from, to, company, gtin, productionStep, lgtinOwningCompanyId) => {
    let queryString = `/api/request_log_aggregation?fromDate=${from}&toDate=${to}&endpoint=/api/history`
    return completeAgentQuery(queryString, company, gtin, productionStep, lgtinOwningCompanyId)
  },

  getDetailsCreation: (from, to, company, gtin, productionStep, lgtinOwningCompanyId) => {
    let queryString = `/api/request_log_aggregation?fromDate=${from}&toDate=${to}&endpoint=/api/creation_event`
    return completeAgentQuery(queryString, company, gtin, productionStep, lgtinOwningCompanyId)
  },

  getDetailsCapture: (from, to, company, gtin, productionStep, lgtinOwningCompanyId) => {
    let queryString = `/api/request_log_aggregation?fromDate=${from}&toDate=${to}&endpoint=/api/capture`
    return completeAgentQuery(queryString, company, gtin, productionStep, lgtinOwningCompanyId)
  },

  getDetailsLGTIN: (from, to, company, gtin, productionStep, lgtinOwningCompanyId) => {
    let queryString = `/api/request_log_aggregation?fromDate=${from}&toDate=${to}&endpoint=/api/lgtin`
    return completeAgentQuery(queryString, company, gtin, productionStep, lgtinOwningCompanyId)
  },

  getDetailsLGTINGenerate: (from, to, company, gtin, productionStep, lgtinOwningCompanyId) => {
    let queryString = `/api/request_log_aggregation?fromDate=${from}&toDate=${to}&endpoint=/api/lgtin/generate`
    return completeAgentQuery(queryString, company, gtin, productionStep, lgtinOwningCompanyId)
  },
}
