import Agent from './agent'

const agent = new Agent()

export const permissionService = {
  all: (params) => agent.get('api/permission', { params }),
  // one: (id) => agent.get(`api/company/${id}`),
  // create: (data) => agent.post(`api/company`, { data }),
  // delete: (id) => agent.delete(`api/company/${id}`),

  // update: (id, data) => agent.post(`api/company/${id}`, { data }),
  bulkCreate: (data) => agent.post(`api/permission`, { data }),
  bulkDelete: (data) => agent.delete(`api/permission`, { data }),
  bulkUpdate: (data) => agent.put(`api/permission`, { data }),
}
