import Agent from './agent'

const agent = new Agent()

export const userService = {
  all: () => agent.get('/api/user'),
  create: (id, data) => agent.post(`api/user/${id}`, { data }),
  update: (id, data) => agent.put(`api/user/${id}`, { data }),
  delete: (id) => agent.delete(`api/user/${id}`),
}
