import Agent, { getCredentials } from './agent'
import { rolesService } from './roles'
import { toast, APP_BASE_URL } from 'utils/helpers'

// Todo: we should try using react hooks for such stuff.
// in combination with swr you can can easly share global state like auth
// and other stuff in localstorage - currently localstorage is missused as
// 'global state'
const agent = new Agent()

let credentials

if (getCredentials())
  rolesService
    .userRoles()
    .then((response) => {
      if (response) {
        localStorage.setItem('userData', JSON.stringify(response))

        if (window.location.pathname === '/login') window.location = '/gtin/-/lot/-'
      }
    })
    .catch((error) => {
      localStorage.removeItem('userData')
      logout()
    })

const handleCredentialsSuccess = async (result) => {
  if (!result) return Promise.reject('401 - Unable to login')

  const { accessToken, type, username } = result

  if (accessToken) {
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('type', type)
    localStorage.setItem('username', username)

    await rolesService.userRoles().then((userData) => {
      localStorage.setItem('userData', JSON.stringify(userData))
    })

    credentials = result
    return Promise.resolve(result)
  } else {
    return Promise.reject('Unable to login')
  }
}

const clearClientStorage = () => {
  credentials = undefined
  localStorage.removeItem('accessToken')
  localStorage.removeItem('type')
  localStorage.removeItem('username')
  localStorage.removeItem('userData')
  localStorage.removeItem('gtin')
  localStorage.removeItem('lot')
  localStorage.removeItem('ident')
  localStorage.removeItem('companyManagementForm')
  sessionStorage.removeItem('urls')
  localStorage.removeItem('cookies')
}

const handleCredentialsFailure = (err) => {
  clearClientStorage()

  if (err && Array.isArray(err) && err.contains('401')) {
    toast.error('Invalid username or password')
  } else {
    toast.error('Something went wrong')
  }

  return Promise.reject(err)
}

export const login = ({ username, password }) => {
  return agent
    .post(`${APP_BASE_URL}/api/login`, {
      data: JSON.stringify({ username, password }),
    })
    .then(handleCredentialsSuccess)
    .catch(handleCredentialsFailure)
}

export const logout = () => {
  clearClientStorage()
  window.location = '/login'
}

export const isLoggedIn = () => {
  return !!credentials
}
