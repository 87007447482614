import Agent from './agent'

const agent = new Agent()

export const rolesService = {
  all: () => agent.get('api/role'),
  one: (authority) => agent.get(`api/role/${authority}`),
  create: (data) => agent.post(`api/role/${data.authority}`, { data }),
  update: (authority, data) => agent.put(`api/role/${authority}`, { data }),
  delete: (authority) => agent.delete(`api/role/${authority}`),
  userRoles: () => agent.get('api/userdata'),
}
