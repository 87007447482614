import Agent from './agent'

const agent = new Agent()

export const markingIdentifier = {
  all: () => agent.get('api/marking_identifier'),
  one: (markingIdentifier) => agent.get(`api/marking_identifier/${markingIdentifier}`),
  create: (markingIdentifier, data) =>
    agent.post(`api/marking_identifier/${markingIdentifier}`, {
      data: `${data}`,
      headers: { 'Content-Type': 'text/plain' },
    }),
  update: (markingIdentifier, data) =>
    agent.put(`api/marking_identifier/${markingIdentifier}`, {
      data: `${data}`,
      headers: { 'Content-Type': 'text/plain' },
    }),
  delete: (markingIdentifier) => agent.delete(`api/marking_identifier/${markingIdentifier}`),
  userRoles: () => agent.get('api/userdata'),
}
