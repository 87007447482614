import axios from 'axios'
import { APP_BASE_URL } from 'utils/helpers'

export const getCredentials = () => {
  // return true
  if (localStorage.getItem('accessToken')) {
    return {
      accessToken: localStorage.getItem('accessToken'),
      type: localStorage.getItem('type'),
      username: localStorage.getItem('username'),
      role: localStorage.getItem('userData'),
    }
  } else {
    return null
  }
}

export default class Agent {
  constructor(baseUrl) {
    axios.defaults.baseURL = baseUrl || APP_BASE_URL
  }

  async request(method, url, options) {
    // const credentials = await getCredentials()
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error && error.status === 401) {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('type')
          localStorage.removeItem('username')
          localStorage.removeItem('userData')
          localStorage.removeItem('gtin')
          localStorage.removeItem('lot')

          if (window.location.pathname !== '/login') {
            window.location.href = '/login'
          }
        }
        if (error && error.response && error.response.data) {
          return Promise.reject(error)
        }
        return Promise.reject(error)
      },
    )
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*',
      ...(getCredentials() && getCredentials().accessToken
        ? {
            Authorization: `Bearer ${getCredentials() && getCredentials().accessToken}`,
          }
        : {}),
    }
    return axios({
      method,
      url,
      ...options,
    })
      .then((response) => {
        // console.log(response.request.responseURL)
        return response.data
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('type')
          localStorage.removeItem('username')
          localStorage.removeItem('userData')
          localStorage.removeItem('gtin')
          localStorage.removeItem('lot')

          if (window.location.pathname !== '/login') {
            window.location.href = '/login'
          }
        } else if (error && error.response && error.response.data && error.response.data.message) {
          return Promise.reject(error.response.data.message)
        } else {
          return Promise.reject(error)
        }
      })
    // }
  }

  get(url, options) {
    return this.request('GET', url, options)
  }

  put(url, options) {
    return this.request('PUT', url, options)
  }

  post(url, options) {
    return this.request('POST', url, options)
  }

  delete(url, options) {
    return this.request('DELETE', url, options)
  }
}
