import { toast } from 'react-toastify'
import React, { Fragment } from 'react'

const handleAsync = (promise) => {
  return promise.then((data) => [data, undefined]).catch((error) => Promise.resolve([undefined, error]))
}

/*
 * content: string | string[]
 * contentTitle: string
 * toastType: success | error | warning | info | default
 * contentType: string
 */
const handleToastMessage = (content, contentTitle, toastType, contentType) => {
  toast(
    <div>
      <b>{contentTitle}</b>
      <br />
      <div>
        {contentType !== 'array' ? (
          content
        ) : (
          <div>
            {content.map((line, index) => (
              <Fragment key={index}>
                {index > 0 && <br />}
                {line}
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </div>,
    { type: toastType },
  )
}

const prettifyToastError = (error) => {
  // Error could also be an object type, we need to extract the message out of it
  if (typeof error === 'object') error = error.message
  const errorMessage = {
    title: 'Error',
    content: error,
    type: 'string',
  }

  // handles error messages comming from the backend
  const regex = /^(.*?)"(.*?)"/
  const matches = error.match(regex)

  if (matches && matches.length > 2) {
    errorMessage.title = matches[1]
    if (matches[2].includes('lgtin')) {
      errorMessage.content = handleLGTINMessage(matches[2])
      errorMessage.type = 'array'
    } else {
      errorMessage.content = matches[2]
    }
  } else {
    // handles error messages related to the GTIN
    if (error.includes('Gtin')) {
      errorMessage.content = handleWrongGTINMessage(error)
      errorMessage.type = 'array'
    }
  }
  if (errorMessage.type === 'string') {
    // We slice the error message to 100 characters chunks to avoid long toast boxes
    errorMessage.content = sliceLongStringByWhitespace(errorMessage.content, 80)
  }
  return errorMessage
}
const sliceLongStringByWhitespace = (inputString, maxLength) => {
  // Check if the input string length is within the max limit
  if (inputString.length <= maxLength) {
    return [inputString]
  }

  const words = inputString.split(/\s+/) // Split the string into words
  let currentLine = words[0]
  const result = []

  for (let i = 1; i < words.length; i++) {
    // Check if adding the next word exceeds the maximum length
    if (currentLine.length + words[i].length + 1 <= maxLength) {
      currentLine += ' ' + words[i] // Add the word to the current line
    } else {
      // Current line is full, push it to the result and start a new line
      result.push(currentLine)
      currentLine = words[i]
    }
  }

  // Push the last line into the result
  result.push(currentLine)

  return result
}

const handleLGTINMessage = (message) => {
  const regex = /^(.*lgtin:)\s*(.*)$/
  const matches = message.match(regex)
  if (matches) {
    return [matches[1], matches[2]]
  }
  return [message]
}
const handleWrongGTINMessage = (message) => {
  // Component 'Gtin' with value '04064074000025' contains invalid check digit '5', corrected value is '04064074000027' (check digit '7')
  const regex = /(.*invalid)\s(.*)/
  // Component 'Gtin' with value '040640.7400316' does not match pattern '^(?<Indicator>[0-9])(?<CompanyPrefixAndItemReference>[0-9]{12})(?<CheckDigit>[0-9])$'
  const regex2 = /(.*pattern)\s(.*)/

  let matches = message.match(regex)
  if (matches) {
    return [matches[1], matches[2]]
  }
  matches = message.match(regex2)
  if (matches) {
    return [matches[1], matches[2]]
  }
  return [message]
}

// use configuration from index.html - index.html gets replace on container startup
const APP_BASE_URL = window.APP_BASE_URL || process?.env.REACT_APP_BASE_URL

const isTrue = (val) => {
  const correctedValue = val === 'true' || val === true // env variables are parsed as strings
  return correctedValue
}

const IsLoginRequired = () => {
  if (window.APP_FEATURE_LOGIN_REQUIRED && window.APP_FEATURE_LOGIN_REQUIRED !== false) {
    return isTrue(window.APP_FEATURE_LOGIN_REQUIRED)
  } else {
    return isTrue(process?.env.REACT_APP_FEATURE_LOGIN_REQUIRED)
  }
}

const getStageName = () => {
  var rawBaseUrl = window.APP_BASE_URL || process?.env.REACT_APP_BASE_URL
  var baseUrlsS = rawBaseUrl.split('.')

  if (baseUrlsS.length === 0) return ''
  var prefixBurlS = baseUrlsS[0].split('-')

  if (prefixBurlS.length === 0) return ''
  var subName = prefixBurlS[prefixBurlS.length - 2]
  return subName
}

const toggleTableDetails = (index, details, setDetails) => {
  const position = details.indexOf(index)
  let newDetails = details.slice()
  if (position !== -1) {
    newDetails.splice(position, 1)
  } else {
    newDetails = [...details, index]
  }
  setDetails(newDetails)
}

const copyToClipboard = (content, fieldName) => {
  navigator.clipboard.writeText(content)
  const contentTitle = `Copied the ${fieldName}`
  handleToastMessage(content, contentTitle, 'default')
}

export {
  toast,
  handleAsync,
  handleToastMessage,
  prettifyToastError,
  APP_BASE_URL,
  IsLoginRequired,
  getStageName,
  toggleTableDetails,
  copyToClipboard,
}
