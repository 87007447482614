import Agent from './agent'

const agent = new Agent()

export const transactionServiceDay = {
  getDetails: (day, api, companyId, gtin, productionStep, lgtinOwningCompanyId) => {
    let url = `/api/request_log?requestDate=${day}&endpoint=${api}`
    if (companyId) url += `&companyId=${companyId}`
    if (gtin) url += `&gtin=${gtin}`
    if (productionStep) url += `&productionStep=${productionStep}`
    if (lgtinOwningCompanyId) url += `&lgtinOwningCompanyId=${lgtinOwningCompanyId}`

    return agent.get(url)
  },
}
