import Agent from './agent'

const agent = new Agent()

export const companyService = {
  all: () => agent.get('/api/company'),
  one: (id) => agent.get(`/api/company/${id}`),
  create: (data) => agent.post(`/api/company/*`, { data }),
  delete: (id) => agent.delete(`/api/company/${id}`),
  update: (id, data) => agent.put(`/api/company/${id}`, { data }),
}
